import { FETCH_SETTINGS, ADD_PARAMS_SETTINGS, TRANSLATIONS_LABEL_TAG, REMOVE_PARAMS_SETTING } from '../actions/types';
import { LOGIN } from '../components/Login/actions/types';

const initialState = {
	params: null,
	show_label_tag: false
};

export default function (state = initialState, action) {
	switch (action.type) {
    case FETCH_SETTINGS:
			return {
				...state,
				...action.payload,
			};
		case ADD_PARAMS_SETTINGS:
			return {
				...state,
				params: action.payload,
			};
		case REMOVE_PARAMS_SETTING:
			const aux = state.params ? Object.keys(state.params)
      .filter(key => !action.payload.includes(key))
      .reduce((obj, key) => {
        obj[key] = state.params[key];
        return obj;
      }, {}) : {};
			return {
				...state,
				params: Object.entries(aux).length === 0 ? null : aux,
			};
		case TRANSLATIONS_LABEL_TAG:
			return {
				...state,
				show_label_tag: action.payload,
			};
		case LOGIN:
			return {
				...state,
				...(!action.payload.login && action.payload.confirmed && { params: null }),
			};
		default:
			return state;
	}
}
