import axios from 'axios';
import { WHOAMI, GET_CONTACTS_LIST } from './types';
import { SELECT_CLIENT } from '../../Login/actions/types';
import { store } from '../../../store.js';
import eventsService from '../../../helpers/Events';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { logoutAction, handleSelectClientAction, loginAutomaticAction } from '../../Login/actions/loginActions';
import { convertToFormData, addDelayAction, handleSplashScreenAction, loadLayouts } from '../../Generic/actions/genericActions';
import { removeSettingsAction } from '../../../actions/settingsActions';
import { handleRedirect } from '../../../actions/genericActions';
import { fetchTranslationsAction, initializeTranslations, loadTranslations } from '../../../actions/translationsActions';
import { dateToString } from '../../../helpers/functions/functions';
import { setPromptDialogIsDirty } from '../../../components/Generic/actions/promptActions';
import { push } from 'connected-react-router';
import { RESET_TOKEN, LOGIN, REFRESH_TOKEN_DATE } from '../../Login/actions/types';
import { reset } from 'redux-form';
import { postTicketAction } from '../../Tickets/actions/ticketsActions';
import { fetchActivitybyTokenAction } from '../../Activities/actions/activitiesActions';
import { apiRequest } from '../../../helpers/functions/request';
import moment from 'moment';
import { setWebsocketToken } from '../../Chat/actions/websocketsActions';

export const whoamiAction =
	(login = false, redirect = false) =>
	(dispatch) => {
		const state = store.getState();

		axios
			.get(state.config.apihost + `/auth/whoami`, { withCredentials: true })
			.then((res) => {
				if (!state.login.login) {
					dispatch(handleSelectClientAction(state.settings.clients.find((client) => client.id === res.data.client_id)));

					dispatch({
						type: LOGIN,
						payload: {
							login: true,
							confirmed: true,
							email_confirm: res.data.email,
						},
					});
				}

				dispatch({
					type: WHOAMI,
					payload: res.data,
				});

				dispatch({
					type: REFRESH_TOKEN_DATE,
					payload: {
						token_expires_at: res.data.token_expires_at,
					},
				});

				if (login || res.data.language !== getActiveLanguage(state.localize).code) {
					dispatch(fetchTranslationsAction(res.data.language));
				}

				return res.data;
			})
			.then((user) => {
				if (redirect) {
					if (user.status === '10') {
						dispatch(push('/myprofile'));
						if (window.self === window.top) {
							dispatch(
								setPromptDialogIsDirty({
									is_dirty: true,
									path_allowed: ['/myprofile'],
									prompt_dialog_title: 'prompt_user_incomplete_title',
									prompt_dialog_description: 'prompt_user_incomplete_description',
									allow_resume: false,
								})
							);
						}
					} else if (state.settings.params !== null && typeof state.settings.params.tickets !== 'undefined') {
						dispatch(push('/case/' + state.settings.params.tickets));
					} else if (state.settings.params !== null && typeof state.settings.params.activities !== 'undefined') {
						dispatch(fetchActivitybyTokenAction(state.settings.params.activities));
					} else if (state.settings.params !== null && typeof state.settings.params.conditions_id !== 'undefined') {
						//dispatch(push('/dashboard'));
						dispatch(postTicketAction(null, state.settings.params));
					} else {
						if (state.settings.feature.dashboard && state.settings.params == null) {
							dispatch(push('/dashboard'));
						} else if (user.scopes.includes('lists.all_cases')) {
							dispatch(push('/allcases'));
						} else if (user.scopes.includes('practitioner')) {
							dispatch(push('/mycases'));
						} else if (user.scopes.includes('customer')) {
							if (state.settings.feature.dashboard && state.settings.params === null) {
								dispatch(push('/dashboard'));
							} else if (
								(state.settings.params !== null && typeof state.settings.params.conditions !== 'undefined') ||
								user.ticket_count === 0
							) {
								dispatch(push('/conditions'));
							} else {
								dispatch(push('/mycases'));
							}
						} else {
							dispatch(push('/mycases'));
						}
					}
				}
			})
			.then((user) => {
				//if (state.settings.params === null) {
				dispatch(handleSplashScreenAction(false));
				//}
			})
			.then((user) =>
				dispatch(removeSettingsAction(['tickets', 'activities', 'conditions_id', 'conditions', 'practitioners_id', 'starts_at', 'ends_at']))
			)
			.catch((error) => {
				console.log(error);
				if (
					state.settings.params !== null &&
					typeof state.settings.params.jwt !== 'undefined' &&
					typeof state.settings.params.clients_id !== 'undefined'
				) {
					dispatch(loginAutomaticAction());
				} else {
					dispatch(handleSplashScreenAction(false));
					if (error.response.status === 401 && state.login.login) {
						dispatch(logoutAction());
					}
					console.log(error);
				}
			});
	};

export const loadWhoami =
	(get_translations = false) =>
	async (dispatch) => {
		const state = store.getState();
		const response = await apiRequest({
			method: 'get',
			url: state.config.apihost + `/auth/whoami`,
			alert: 'alert_login',
			handle_error: (error, trigger_event) => {
				if (state.login.login) {
					return dispatch(logoutAction(true));
				} else {
					if (
						state.settings.params !== null &&
						typeof state.settings.params.jwt !== 'undefined' &&
						typeof state.settings.params.clients_id !== 'undefined'
					) {
						dispatch(loginAutomaticAction());
					}

					trigger_event();
				}
			},
		});

		await dispatch(loadLayouts());

		await dispatch(setWebsocketToken());

		if (response.status == 200 && !state.login.login) {
			await dispatch({
				type: SELECT_CLIENT,
				payload: {
					client: state.settings.clients.find((client) => client.id == response.data.current_client),
					platform: 'frontoffice',
				},
			});

			await dispatch({
				type: LOGIN,
				payload: {
					login: true,
					confirmed: true,
					email_confirm: response.data.email,
					token_expires_at: response.data.token_expires_at,
				},
			});
		} else {
			await dispatch({
				type: REFRESH_TOKEN_DATE,
				payload: {
					token_expires_at: response.data.token_expires_at,
				},
			});
		}

		if (get_translations || response.data.language !== getActiveLanguage(state.localize).code) {
			await dispatch(loadTranslations(response.data.language ? response.data.language : state.settings.site.default_language));
		}

		await dispatch({
			type: WHOAMI,
			payload: response.data,
		});

		if (response.data.scopes.includes('translations.toggle')) {
			await dispatch(initializeTranslations(state.settings));
		}

		return;
	};

export const patchCurrentUserAction = (form) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	if (typeof state.form[form].values.profile_photo !== 'undefined' && state.form[form].values.profile_photo !== null) {
		state.form[form].values.profile_photo = state.form[form].values.profile_photo.length > 0 ? state.form[form].values.profile_photo : null;
	}

	if (typeof state.form[form].values.signature_img !== 'undefined' && state.form[form].values.signature_img !== null) {
		state.form[form].values.signature_img = state.form[form].values.signature_img.length > 0 ? state.form[form].values.signature_img : null;
	}

	if (typeof state.form[form].values.birthdate !== 'undefined') {
		state.form[form].values.birthdate = dateToString(
			new Date(
				state.form[form].values.birthdate.toLocaleString('en-US', {
					year: 'numeric',
					month: 'numeric',
					day: 'numeric',
					hour: 'numeric',
					minute: 'numeric',
					hour12: true,
				})
			)
		);
	}

	state.form[form].values.consented = state.form[form].values.consented ? '1' : '0';

	let data = convertToFormData(state.form[form].values);

	const response = await apiRequest({
		method: 'patch',
		url: state.config.apihost + `/auth/users/` + state.users.whoami.id,
		data,
		success_message: translate('alert_patch_current_user'),
		submit_form: form,
	});

	if (state.prompt.is_dirty && response.data.status !== '10') {
		dispatch(setPromptDialogIsDirty({ is_dirty: false }));
	}

	await dispatch(loadWhoami());

	return dispatch(handleRedirect());
};

export const cleanWhoamiAction = () => (dispatch) => {
	dispatch({
		type: WHOAMI,
		payload: '',
	});
};

export const searchSsnAction =
	(form, params = {}, callback = false) =>
	async (dispatch) => {
		const state = store.getState();

		let ssn = state.form[form].values.ssn;

		const response = await apiRequest({
			method: 'get',
			url: state.config.apihost + `/contacts`,
			params: { search: ssn, ...params },
			handle_error: (error, trigger_event) => {
				if (form === 'ticket_invite' && callback) {
					callback('unable_to_search');
					trigger_event();
				}
			},
		});

		if (callback) {
			return callback(response.data.items);
		}

		return;
	};

export const getContactsAction =
	(search, params = {}, callback = false, errorCallback = false) =>
	async (dispatch) => {
		const state = store.getState();

		const response = await apiRequest({
			method: 'get',
			url: state.config.apihost + `/contacts`,
			params: { search: search, status: 100, ...params },
			handle_error: (error, trigger_event) => {
				if (errorCallback) {
					errorCallback();
				} else {
					trigger_event();
				}
			},
		});

		await dispatch({
			type: GET_CONTACTS_LIST,
			payload: response.data,
		});

		if (callback) {
			callback(response.data);
		}

		return;
	};
export const cleanContactsAction = () => async (dispatch) => {
	dispatch({
		type: GET_CONTACTS_LIST,
		payload: [],
	});

	return;
};
export const verifySsnAction = (form, callback) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	let ssn = state.form[form].values.ssn;
	axios
		.get(state.config.apihost + `/users/verify`, {
			params: { ssn: ssn },
		})
		.then((res) => {
			callback('continue_register');
		})
		.catch((error) => {
			console.log(error);
			if (error.response.status === 403) {
				dispatch(callback('already_register'));
				dispatch(
					addDelayAction(null, () =>
						eventsService.triggerEvent('alert', {
							type: 'error',
							message:
								typeof error.response.data.context.message !== 'undefined'
									? translate(error.response.data.context.message)
									: translate(error.response.data.message),
						})
					)
				);
			} else {
				dispatch(callback('unable_to_search'));
				dispatch(
					addDelayAction(null, () =>
						eventsService.triggerEvent('alert', {
							type: 'error',
							message:
								typeof error.response.data.context.message !== 'undefined'
									? translate(error.response.data.context.message)
									: translate(error.response.data.message),
						})
					)
				);
			}
		});
};

export const saveLayoutAction = (layout) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	axios
		.patch(state.config.apihost + `/users/` + state.users.whoami.id, { settings: JSON.stringify(layout) })
		.then((res) => {
			dispatch({
				type: WHOAMI,
				payload: res.data,
			});

			dispatch(loadWhoami());

			dispatch(
				addDelayAction(push('/dashboard'), () =>
					eventsService.triggerEvent('alert', { type: 'success', message: translate('alert_update_dashboard_builder') })
				)
			);
		})
		.catch((err) => {
			console.log(err);
			console.log('Error fetching data.');

			dispatch(addDelayAction(null, () => eventsService.triggerEvent('alert', { type: 'error', message: err.message })));
		});
};

export const sendRequestCodeAction = (form, handleRequestCode) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	var data = new FormData();
	data.append('medium', state.form[form].values.medium_type);
	data.append('value', state.form[form].values.medium_value);

	await apiRequest({
		method: 'post',
		url: state.config.apihost + `/reset`,
		data,
		alert: state.login.login ? 'alert' : 'alert_login',
		success_message: translate('alert_success_request_code'),
		submit_form: form,
});

	handleRequestCode();

	return;
};

export const sendValidateCodeAction = (form, handleValidateCode, handleFormDisplay) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize); 
	var data = new FormData();
  data.append('medium', state.form['request_code'].values.medium_type);
  data.append('value', state.form['request_code'].values.medium_value);
  data.append('token', state.form[form].values.recovery_token);

	const response = await apiRequest({
		method: 'post',
		url: state.config.apihost + `/reset/verify`,
		data,
		alert: state.login.login ? 'alert' : 'alert_login',
		success_message: translate('alert_success_validate_code'),
		submit_form: form,
		handle_error: (error, trigger_event) => {
			handleFormDisplay();
			trigger_event();
      dispatch(reset(form));
		},
	});

	await dispatch({
		type: RESET_TOKEN,
		payload: {
      reset_medium : state.form['request_code'].values.medium_type,
      reset_value : state.form['request_code'].values.medium_value,
      reset_token : state.form[form].values.recovery_token
    },
	});

  await dispatch(removeSettingsAction(['recovery_token', 'recovery_medium', 'recovery_value']));
	
  await dispatch(reset(form));
	await dispatch(reset('request_code'));

	handleValidateCode();
	handleFormDisplay();

	return;
};

export const sendNewPasswordAction = (form, handleRecovery) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	var data = new FormData();
	data.append('password', state.form[form].values.password);
	data.append('password_verify', state.form[form].values.password_verify);
	data.append('token', state.login.reset_token);
  data.append('medium', state.login.reset_medium);
  data.append('value', state.login.reset_value);

	await apiRequest({
		method: 'post',
		url: state.config.apihost + `/reset/password`,
		data,
		success_message: translate('alert_success_new_password_created'),
		alert: state.login.login ? 'alert' : 'alert_login',
		submit_form: form,
	});

  await dispatch({
		type: RESET_TOKEN,
		payload: {
      reset_medium : null,
      reset_value : null,
      reset_token : null
    },
	});


  await dispatch(reset('code'));
	await dispatch(reset('request_code'));

	if (state.login.login) {
		return dispatch(logoutAction(true));
	} else {
		return handleRecovery();
	}
};

export const resendTokenAction = (form) => (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	var body = new FormData();
	body.append('medium', 'email');
	body.append('value', state.form[form].values.email);

	axios
		.post(state.config.apihost + `/users/contact/resend`, body)
		.then((res) => {
			dispatch(
				addDelayAction(() =>
					eventsService.triggerEvent(state.login.login ? 'alert' : 'alert_login', {
						type: 'success',
						message: translate('alert_success_code_resend'),
					})
				)
			);
		})
		.catch((err) => {
			console.log(err);
			console.log('Error fetching data.');

			var error_message = typeof err.response.data.message !== 'undefined' ? err.response.data.message : err.message;
			dispatch(
				addDelayAction(null, () =>
					eventsService.triggerEvent(state.login.login ? 'alert' : 'alert_login', { type: 'error', message: error_message })
				)
			);
		});
};

export const createInsuranceUserAction =
	(form, callback = false) =>
	async (dispatch) => {
		const state = store.getState();
		const translate = getTranslate(state.localize);

		state.form[form].values.expired_at = moment(state.form[form].values.expired_at).format('YYYY-MM-DD');

		let data = convertToFormData(state.form[form].values);

		const response = await apiRequest({
			method: 'post',
			url: state.config.apihost + '/users/' + state.users.whoami.id + '/billing',
			data,
			success_message: translate('alert_create_user_insurance'),
			submit_form: form,
		});

		if (callback) {
			callback(true);
		}
		await dispatch(loadWhoami());

		return;
	};

export const patchInsuranceUserAction =
	(form, callback = false) =>
	async (dispatch) => {
		const state = store.getState();
		const translate = getTranslate(state.localize);
		state.form[form].values.expired_at = moment(state.form[form].values.expired_at).format('YYYY-MM-DD');
		let data = convertToFormData(state.form[form].values);

		const response = await apiRequest({
			method: 'patch',
			url: state.config.apihost + '/users/' + state.users.whoami.id + '/billing/' + state.form[form].values.id,
			data,
			success_message: translate('alert_updated_user_insurance'),
			submit_form: form,
		});

		if (callback) {
			callback(true);
		}
		await dispatch(loadWhoami());

		return;
	};
export const removeInsuranceUserAction = (form, handleExternalList) => async (dispatch) => {
	const state = store.getState();
	const translate = getTranslate(state.localize);

	if (state.form[form].values.id) {
		const response = await apiRequest({
			method: 'delete',
			url: state.config.apihost + '/users/' + state.users.whoami.id + '/billing/' + state.form[form].values.id,
			success_message: translate('alert_delete_user_insurance'),
			submit_form: form,
		});

		handleExternalList();
		await dispatch(loadWhoami());
		return dispatch(reset(form));
	}
};
export const patchUserAction =
	(user_id, groups_id, callback = false) =>
	async (dispatch) => {
		const state = store.getState();
		const translate = getTranslate(state.localize);

		const data = {
			groups_id: groups_id,
		};
		const response = await apiRequest({
			method: 'patch',
			url: state.config.apihost + `/users/` + user_id,
			data,
			success_message: translate('alert_patch_current_user'),
		});
		if (callback) {
			callback();
		}
		return;
	};
