import React, { Component } from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Typography, Collapse } from '@mui/material';
import { withRouter } from 'react-router-dom';
import PasswordValidate from './PasswordValidate';
import PasswordForm from './PasswordForm';

class Password extends Component {
	constructor(props) {
		super(props);

		this.state = {
			validate: false,
      recovery_token: this.props.recovery_token ?? null,
      recovery_medium: this.props.recovery_medium ?? null,
      recovery_value: this.props.recovery_value ?? null,
		};
	}
  
  componentDidMount() {
    if (typeof this.props.recovery_token === 'undefined' && this.props.login.reset_token != null) {
      this.setState({
        recovery_token: this.props.login.reset_token,
        recovery_medium: this.props.login.reset_medium,
        recovery_value: this.props.login.reset_value,
        validate: true,
      });

    }
  }

	handleValidateCode = () => {
		this.setState({
			validate: !this.state.validate,
		});
	};

	render() {
		const { translate } = this.props;

		return (
			<div>
				{!this.props.hiddeTitles ? (
					<React.Fragment>
						<Typography align="center" variant="h2" component="h2" gutterBottom>
							{translate('users_change_password_title')}
						</Typography>
						<Typography align="center" variant="subtitle2" component="p" gutterBottom>
							{translate('users_change_password_subtitle')}
						</Typography>
					</React.Fragment>
				) : (
					''
				)}
				<Collapse in={!this.state.validate}>
					<PasswordValidate handleValidateCode={this.handleValidateCode} handleRecovery={this.props.handleRecovery} recovery_token={this.state.recovery_token} recovery_medium={this.state.recovery_medium} recovery_value={this.state.recovery_value} />
				</Collapse>
				<Collapse in={this.state.validate}>
					<PasswordForm handleValidateCode={this.handleValidateCode} handleRecovery={this.props.handleRecovery} />
				</Collapse>
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
  login: state.login,
});

export default connect(mapStateToProps)(withRouter(Password));
