import {
	LOGIN,
	LOGIN_BANKID,
	BANKID_PROGRESS,
	LOGIN_FREJAID,
	FREJAID_PROGRESS,
	LOGIN_NETID,
	NETID_PROGRESS,
	REFRESH_TOKEN,
	SELECT_CLIENT,
	BANKID_QRCODE,
	RESET_TOKEN,
	LOGIN_SELF_REGISTRATION,
	REFRESH_TOKEN_DATE
} from '../actions/types';

const initialState = {
	login: false,
	expires_in: null,
	token_expires_at: null,
	bankid_progress: false,
	frejaid_progress: false,
	netid_progress: false,
	netid_check_tries: 0,
	session_id: '',
	autostarttoken: '',
	client: null,
	platform: null,
	reset_medium: null,
  reset_value: null,
  reset_token: null,
	self_registration: false,
	confirmed: true,
	email_confirm: '',
	type: 'regular',
	message: ''
};

export default function (state = initialState, action) {
	switch (action.type) {
		case LOGIN:
			return {
				...state,
				login: action.payload.login,
				expires_in: action.payload.expires_in,
				token_expires_at: action.payload.token_expires_at,
				confirmed: action.payload.confirmed,
				email_confirm: action.payload.email_confirm,
				...(typeof action.payload.self_registration !== 'undefined' && { self_registration: action.payload.self_registration }),
				...(typeof action.payload.client !== 'undefined' && { client: action.payload.client }),
				...(typeof action.payload.platform !== 'undefined' && { platform: action.payload.platform }),
				...(typeof action.payload.type !== 'undefined' ? { type: action.payload.type } : { type: initialState.type }),
				...(typeof action.payload.message !== 'undefined' ? { message: action.payload.message } : { message: initialState.message }),
			};
		case LOGIN_BANKID:
			return {
				...state,
				login: action.payload.login,
				bankid_progress: action.payload.progress,
				expires_in: action.payload.expires_in,
				token_expires_at: action.payload.token_expires_at,
				session_id: '',
				confirmed: action.payload.confirmed,
				...(typeof action.payload.type !== 'undefined' ? { type: action.payload.type } : { type: initialState.type }),
				...(typeof action.payload.message !== 'undefined' ? { message: action.payload.message } : { message: initialState.message }),
			};
		case BANKID_PROGRESS:
			return {
				...state,
				bankid_progress: action.payload.bankid_progress,
				session_id: action.payload.session_id,
			};
		case LOGIN_FREJAID:
			return {
				...state,
				login: action.payload.login,
				frejaid_progress: action.payload.progress,
				expires_in: action.payload.expires_in,
				token_expires_at: action.payload.token_expires_at,
				session_id: '',
				confirmed: action.payload.confirmed,
				...(typeof action.payload.type !== 'undefined' ? { type: action.payload.type } : { type: initialState.type }),
				...(typeof action.payload.message !== 'undefined' ? { message: action.payload.message } : { message: initialState.message }),
			};
		case FREJAID_PROGRESS:
			return {
				...state,
				frejaid_progress: action.payload.frejaid_progress,
				session_id: action.payload.session_id,
			};
		case BANKID_QRCODE:
			return {
				...state,
				session_id: action.payload.session_id,
				autostarttoken: action.payload.autostarttoken,
			};
		case LOGIN_NETID:
			return {
				...state,
				login: action.payload.login,
				netid_progress: action.payload.progress,
				netid_check_tries: 0,
				session_id: '',
			};
		case NETID_PROGRESS:
			state.netid_check_tries++;
			return {
				...state,
				netid_progress: action.payload.netid_progress,
				session_id: action.payload.session_id,
				netid_check_tries: action.payload.netid_progress ? state.netid_check_tries++ : 0,
			};
		case REFRESH_TOKEN:
			return {
				...state,
				login: action.payload.login,
				expires_in: action.payload.expires_in,
				token_expires_at: action.payload.token_expires_at,
			};
		case SELECT_CLIENT:
			return {
				...state,
				client: action.payload.client,
				platform: action.payload.platform,
			};
		case RESET_TOKEN:
			return {
				...state,
				reset_medium: action.payload.reset_medium,
        reset_value: action.payload.reset_value,
        reset_token: action.payload.reset_token,
			};
		case LOGIN_SELF_REGISTRATION:
			return {
				...state,
				self_registration: action.payload.self_registration,
				email_confirm: action.payload.email_confirm,
			};
		case REFRESH_TOKEN_DATE:
			return {
				...state,
				token_expires_at: action.payload.token_expires_at,
			};
		default:
			return state;
	}
}
