import React, { useState, useEffect } from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Main from '../../Generic/Form/MainReinitialize';
import { TYPES as FORM_TYPES } from '../../Generic/FormTypes';
import { reduxForm } from 'redux-form';
import Alert from '@mui/material/Alert';
import { verifySsnAction } from '../../Users/actions/usersActions';
import { push } from 'connected-react-router';
import { my_ssn_validations } from '../../../helpers/functions/functions';
import { Typography, Grid } from '@mui/material';

export const IdentityVerification = ({ registration_fields, settings, onSubmitSuccess, verify, verifySsnAction, push, ...props }) => {
	const [search, setSearch] = useState(false);
	const [searching, setSearching] = useState(false);
	const [showAlert, setShowAlert] = useState(false);

	const verifySsn = () => {
		setSearching(true);
		setSearch(true);

		verifySsnAction('create_new_account_search', (data) => {
			switch (data) {
				case 'already_register':
					setSearch(true);
					setSearching(false);
					setShowAlert(true);
					verify(false);
					break;
				case 'unable_to_search':
					setSearch(true);
					setSearching(false);
					setShowAlert(false);
					verify(false);
					break;
				default:
					setSearch(true);
					setSearching(false);
					setShowAlert(false);
					verify(true);
					break;
			}
		});
	};

	const clearSsn = () => {
		setSearch(false);
		setSearching(false);
	};

	const handleConfig = () => {
		const { translate } = props;

		const structure = {
			form: 'create_new_account_search',
			id: 'create_new_account_search',
			title: '',
			subtitle: '',
			helperText: '',
			maxWidth: 'xl',
			fields_grid_layout: {
				xs: 12,
			},

			columns: [
				{
					id: 'column-1',
					text: '',
					grid_layout: { xs: 12 },
					separators: [
						{
							id: 'separator-1-1',
							text: '',
							collapse: false,
							divider: false,
							fields: [
								{
									id: 'ssn',
									name: 'ssn',
									label: translate('ticket_form_invite_field_ssn'),
									placeholder: translate('ssn_field_placeholder_' + settings.auth.ssnValidation),
									type:
										registration_fields.find((elem) => elem.field == 'ssn') &&
										registration_fields.find((elem) => elem.field == 'ssn').display
											? FORM_TYPES.INPUT_ADORNMENT
											: FORM_TYPES.NONE,
									validate: {
										required:
											registration_fields.find((elem) => elem.field == 'ssn') &&
											registration_fields.find((elem) => elem.field == 'ssn').required,
										...(settings.auth.ssnValidation
											? {
													functionValidation: [
														{
															validation: (value) =>
																typeof my_ssn_validations[settings.auth.ssnValidation] !== 'undefined'
																	? my_ssn_validations[settings.auth.ssnValidation](value)
																	: true,
														},
													],
											  }
											: {}),
									},
									grid_layout: { xs: 12, md: 6 },
									onValidValue: () => {
										if (!search) {
											verifySsn();
										}
									},
									adornment: {
										action: verifySsn,
										triggered: searching,
									},
									onChange: (value) => clearSsn(value),
									initialvalue: '',
								},
								{
									id: 'redirect',
									type: showAlert ? FORM_TYPES.EXTERNAL : FORM_TYPES.HIDDEN,
									grid_layout: { xs: 12, md: 6 },
									content: (
										<Alert
											severity="warning"
											className="generic-pointer"
											onClick={() => {
												push('/login');
											}}
										>
											{translate('creat_alert_notification')}
										</Alert>
									),
								},
							],
						},
					],
				},
			],
		};

		return structure;
	};

	const configuration = handleConfig();

	return (
		<Grid container>
			<Typography sx={{ mt: 2 }}>{props.translate('identity_verification_info')}</Typography>
			<Main form={configuration.form} formData={configuration} />
		</Grid>
	);
};

const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize),
	registration_fields: state.settings.users.registration_fields,
	settings: state.settings,
});

const mapDispatchToProps = (dispatch) => ({
	verifySsnAction: (arg1, arg2) => dispatch(verifySsnAction(arg1, arg2)),
	push: (path) => dispatch(push(path)),
});

const ConnectedIdentityVerification = reduxForm({
	form: 'create_new_account_search',
	destroyOnUnmount: false,
	onSubmit: (values, dispatch, props) => {
		if (!props.valid) {
			props.onSubmitSuccess();
		}
	},
})(IdentityVerification);

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedIdentityVerification);
