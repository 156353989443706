import { createTheme, responsiveFontSizes, adaptV4Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classes from './classes';
import { isMobileSize, LightenDarkenColor } from '../helpers/functions/functions';
import { blueGrey } from '@mui/material/colors';
import {
	NettoWeb,
	RobotoRegular,
	RobotoBold,
	RobotoBlack,
	RobotoBlackItalic,
	RobotoBoldItalic,
	RobotoItalic,
	RobotoLight,
	RobotoLightItalic,
	RobotoMedium,
	RobotoMediumItalic,
	RobotoThin,
	RobotoThinItalic,
	WorkSans,
	GlyphiconsHalflings,
	ChronicleDeck_Black,
	ChronicleDeck_BlackItalic,
	ChronicleDeck_Bold,
	ChronicleDeck_BoldItalic,
	ChronicleDeck_Italic,
	ChronicleDeck_Roman,
	ChronicleDeck_SemiBold,
	ChronicleDeck_SemiItalic,
	ChronicleText_Bold,
	ChronicleText_BoldItalic,
	ChronicleText_Italic,
	ChronicleText_Roman,
	ChronicleText_SemiBold,
	ChronicleText_SemiItalic,
	Maax_Bold_Italic,
	Maax_Bold,
	Maax_Italic,
	Maax_Regular,
	OpenSansBold,
	OpenSansBoldItalic,
	OpenSansExtraBold,
	OpenSansExtraBoldItalic,
	OpenSansItalic,
	OpenSansLight,
	OpenSansLightItalic,
	OpenSansMedium,
	OpenSansMediumItalic,
	OpenSansRegular,
	OpenSansSemiBold,
	OpenSansSemiBoldItalic,
} from './fonts';

const allowedFontFamilies = [
	NettoWeb,
	RobotoRegular,
	RobotoBold,
	RobotoBlack,
	RobotoBlackItalic,
	RobotoBoldItalic,
	RobotoItalic,
	RobotoLight,
	RobotoLightItalic,
	RobotoMedium,
	RobotoMediumItalic,
	RobotoThin,
	RobotoThinItalic,
	WorkSans,
	GlyphiconsHalflings,
	ChronicleDeck_Black,
	ChronicleDeck_BlackItalic,
	ChronicleDeck_Bold,
	ChronicleDeck_BoldItalic,
	ChronicleDeck_Italic,
	ChronicleDeck_Roman,
	ChronicleDeck_SemiBold,
	ChronicleDeck_SemiItalic,
	ChronicleText_Bold,
	ChronicleText_BoldItalic,
	ChronicleText_Italic,
	ChronicleText_Roman,
	ChronicleText_SemiBold,
	ChronicleText_SemiItalic,
	Maax_Bold_Italic,
	Maax_Bold,
	Maax_Italic,
	Maax_Regular,
	OpenSansBold,
	OpenSansBoldItalic,
	OpenSansExtraBold,
	OpenSansExtraBoldItalic,
	OpenSansItalic,
	OpenSansLight,
	OpenSansLightItalic,
	OpenSansMedium,
	OpenSansMediumItalic,
	OpenSansRegular,
	OpenSansSemiBold,
	OpenSansSemiBoldItalic,
];

export const theme = (settings, client) =>
	responsiveFontSizes(
		createTheme({
			status: {
				neutral: {
					main: blueGrey[200],
					contrastText: '#000',
				},
			},
			palette: {
				tonalOffset: 0.3,
				contrastThreshold: 4.5, // NOTE: To meet the minimum contrast of at least 4.5:1 as defined in WCAG 2.1 Rule 1.4.3
				neutral: {
					main: blueGrey[200],
					light: blueGrey[100],
					dark: blueGrey[300],
					contrastText: '#000',
				},
				natural: {
					main: 'white',
					light: 'white',
					dark: 'white',
					contrastText: '#fff',
				},
				primary: {
					main:
						client?.id && settings?.clients.find((element) => element.id == client.id)?.primary_color
							? settings.clients.find((element) => element.id == client.id).primary_color
							: settings.site?.color_primary
							? settings.site.color_primary
							: '#00A99D',
					contrastText: settings?.styling?.primary_contrast_color,
				},
				secondary: {
					main:
						client?.id && settings?.clients.find((element) => element.id == client.id)?.secondary_color
							? settings.clients.find((element) => element.id == client.id).secondary_color
							: settings.site?.color_secondary
							? settings.site.color_secondary
							: '#17a2b8',
					contrastText: settings?.styling?.secondary_contrast_color,
				},
				video: {
					primary: {
						main: settings?.styling?.videoconference_primary_color
							? settings.styling.videoconference_primary_color
							: settings?.site?.color_primary
							? settings.site.color_primary
							: '#FFFFFF',
					},
					secondary: {
						main: settings?.styling?.videoconference_secondary_color
							? settings.styling.videoconference_secondary_color
							: settings?.site?.color_secondary
							? settings.site.color_secondary
							: '#FFFFFF',
					},
				},
			},
			typography: {
				...(allowedFontFamilies.some((font_family) => font_family.fontFamily === settings?.styling?.font_family_other_sizes) && {
					fontFamily: settings.styling.font_family_other_sizes,
				}),
				useNextVariants: true,
				h1: {
					color: settings?.styling?.text_color,
					...(allowedFontFamilies.some((font_family) => font_family.fontFamily === settings?.styling?.font_family_h1) && {
						fontFamily: settings.styling.font_family_h1,
					}),
				},
				h2: {
					color:
						client?.id && settings?.clients.find((element) => element.id == client.id)?.primary_color
							? settings.clients.find((element) => element.id == client.id).primary_color
							: settings?.site?.color_primary
							? settings.site.color_primary
							: settings?.styling?.text_color,
					...(allowedFontFamilies.some((font_family) => font_family.fontFamily === settings?.styling?.font_family_h2) && {
						fontFamily: settings.styling.font_family_h2,
					}),
				},
				h3: {
					color: settings?.styling?.text_color,
					...(allowedFontFamilies.some((font_family) => font_family.fontFamily === settings?.styling?.font_family_h3) && {
						fontFamily: settings.styling.font_family_h3,
					}),
				},
				h4: {
					color: settings?.styling?.text_color,
					...(allowedFontFamilies.some((font_family) => font_family.fontFamily === settings?.styling?.font_family_h4) && {
						fontFamily: settings.styling.font_family_h4,
					}),
				},
				h5: {
					color: settings?.styling?.text_color,
				},
				h6: {
					color: settings?.styling?.text_color,
				},
				subtitle1: {
					color: settings?.styling?.text_color,
				},
				subtitle2: {
					color: settings?.styling?.text_color,
				},
				body1: {
					color: settings?.styling?.text_color,
				},
				body2: {
					color: settings?.styling?.text_color,
				},
				button: {
					color: settings?.styling?.text_color,
				},
			},
			components: {
				MuiCssBaseline: {
					styleOverrides: {
						...allowedFontFamilies.map((font_family) => ({ '@font-face': font_family })),
					},
				},
				MuiButton: {
					defaultProps: {
						variant: settings?.styling?.button_variant,
					},
					styleOverrides: {
						root: {
							...(settings?.styling?.font_family_button &&
								allowedFontFamilies.some((font_family) => font_family.fontFamily === settings.styling.font_family_button) && {
									fontFamily:
										settings?.styling?.font_family_button &&
										allowedFontFamilies.some((font_family) => font_family.fontFamily === settings.styling.font_family_button)
											? settings.styling.font_family_button
											: '"Roboto", "Helvetica", "Arial", sans-serif',
								}),
							'&$disabled': {
								cursor: 'not-allowed',
								pointerEvents: 'unset',
							},
						},
					},
				},
				MuiButtonGroup: {
					defaultProps: {
						variant: settings?.styling?.button_group_variant,
					},
					styleOverrides: {
						root: {
							...(settings?.styling?.font_family_button_group &&
								allowedFontFamilies.some((font_family) => font_family.fontFamily === settings.styling.font_family_button_group) && {
									fontFamily:
										settings?.styling?.font_family_button_group &&
										allowedFontFamilies.some(
											(font_family) => font_family.fontFamily === settings.styling.font_family_button_group
										)
											? settings.styling.font_family_button_group
											: '"Roboto", "Helvetica", "Arial", sans-serif',
								}),
						},
					},
				},
				MuiLink: {
					defaultProps: {
						underline: 'always',
					},
				},
				// ...(settings?.styling?.link_hover_color && {
				// 	MuiLink: {
				// 		styleOverrides: {
				// 			root: {
				// 				'&:hover': {
				// 					color: settings.styling.link_hover_color,
				// 				},
				// 			},
				// 		},
				// 	},
				// }),
				MuiTableCell: {
					styleOverrides: {
						root: {
							color: settings?.styling?.text_color,
						},
					},
				},
				...(isMobileSize() && {
					MuiTableRow: {
						styleOverrides: {
							hover: {
								'&:hover.muitable-expanded-top-row': {
									backgroundColor:
										client?.id && settings?.clients.find((element) => element.id == client.id)?.primary_color
											? LightenDarkenColor(settings.clients.find((element) => element.id == client.id).primary_color, 70) +
											  ' !important'
											: settings?.site?.color_primary
											? LightenDarkenColor(settings.site.color_primary, 70) + ' !important'
											: LightenDarkenColor('#00A99D', 70) + ' !important',
								},
								'&:hover:not(.muitable-expanded-top-row)': {
									backgroundColor: 'unset !important',
								},
							},
						},
					},
				}),
				MUIDataTableBodyCell: {
					styleOverrides: {
						root: {
							cursor: 'pointer',
						},
					},
				},
				MUIDataTableHeadCell: {
					styleOverrides: {
						root: {
							fontSize: '15px',
							fontWeight: 'bold',
						},
					},
				},
				// MuiTab: {
				// 	styleOverrides: {
				//     "root": {
				//       "&.Mui-selected": {
				//         backgroundColor:
				// 					client?.id && settings?.clients.find((element) => element.id == client.id)?.primary_color
				// 						? settings.clients.find((element) => element.id == client.id).primary_color
				// 						: settings.site?.color_primary
				// 						? settings.site.color_primary
				// 						: '#00A99D',
				//       }
				//     }
				//   }
				// 	},
				// MuiTabs: {
				//   styleOverrides: {
				//     selected: {
				//         backgroundColor: 'orange',
				//         height: 3,
				//     }
				//   },
				// },

				MuiListItemText: {
					styleOverrides: {
						root: {
							whiteSpace: 'pre-wrap',
						},
					},
				},
				MuiIconButton: {
					styleOverrides: {
						root: {
							borderRadius: '8px',
						},
					},
				},
				MuiAvatar: {
					styleOverrides: {
						root: {
							// borderRadius: '8px',
						},
					},
				},
				MuiChip: {
					styleOverrides: {
						root: {
							borderRadius: '8px',
						},
					},
				},
				MuiAppBar: {
					styleOverrides: {
						root: {
							boxShadow: settings?.styling?.navbar_elevation,
						},
					},
				},
			},
		})
	);

export const useStyles = makeStyles((theme) => {
	return {
		'@global': {
			body: {
				margin: '0px',
			},
			'.MuiPickersDay-root.Mui-selected.Mui-disabled': {
				color: 'white',
			},
			'.MuiOutlinedInput-root.Mui-error.datepicker-warning': {
				color: 'inherit',
			},
			'.MuiFormLabel-root.Mui-error.datepicker-warning': {
				color: 'inherit',
			},
			'.MuiFormHelperText-root.Mui-error.datepicker-warning': {
				color: 'inherit',
			},
			'.MuiOutlinedInput-notchedOutline.datepicker-warning': {
				borderColor: 'inherit' + ' !important',
			},
			'.success-alteri-color': {
				backgroundColor: '#00A99D !important',
				color: 'white !important',
				'&:hover': {
					opacity: '0.8 !important',
					backgroundColor: '#00A99D !important',
				},
			},
			'.info-alteri-color': {
				backgroundColor: '#54698C !important',
				color: 'white !important',
				'&:hover': {
					opacity: '0.8 !important',
					backgroundColor: '#54698C !important',
				},
			},
			'.danger-alteri-color': {
				backgroundColor: '#BF0603 !important',
				color: 'white !important',
				'&:hover': {
					opacity: '0.8 !important',
					backgroundColor: '#BF0603 !important',
				},
			},
			'.default-alteri-color': {
				backgroundColor: '#e0e0e0 !important',
				color: 'black !important',
				'&:hover': {
					opacity: '0.8 !important',
					backgroundColor: '#d5d5d5 !important',
				},
			},
			'.icon-danger-alteri-color': {
				color: '#BF0603 !important',
				'&:hover': {
					opacity: '0.8 !important',
					color: '#BF0603 !important',
				},
			},
			'.warning-alteri-color': {
				backgroundColor: '#F4D58D !important',
				color: 'white !important',
				'&:hover': {
					opacity: '0.8 !important',
					backgroundColor: '#F4D58D !important',
				},
			},
			'.icon-warning-alteri-color': {
				color: '#F4D58D !important',
				'&:hover': {
					opacity: '0.8 !important',
					color: '#F4D58D !important',
				},
			},
			'.flexGrow': {
				flexGrow: '1',
			},
			'.iconSmall': {
				fontSize: 20,
			},
			'.leftIcon': {
				marginRight: theme.spacing(1),
			},
			'.rightIcon': {
				marginLeft: theme.spacing(1),
			},
			'.hidden': {
				display: 'none',
			},
			'.divider': {
				margin: theme.spacing(2, 0),
			},
			'.inline': {
				display: 'inline',
			},
			'.disabled-link-cursor': {
				cursor: 'not-allowed',
			},
			...classes(theme),
		},
	};
});
