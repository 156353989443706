import ZoomImage from '../images/zoom_in.svg';
import BackgroundImage from '../../assets/background.svg';
// import BackgroundImage from '../../assets/background_neutral.svg';
import { convertHex } from '../../helpers/functions/functions';

const generic = (theme) => {
	return {
		'.generic-defaultlayout-main': {
			// display: 'flex',
			color: (props) => props?.settings?.styling?.layout_background_contrast_color,
			// backgroundColor: '#E6F0F9',
			backgroundColor: (props) => props?.settings?.styling?.layout_background_color,
			// backgroundImage: `url(${BackgroundImage})`,
			backgroundImage: (props) =>
				props?.settings?.styling?.files?.layout_background_image?.uuid
					? `url(${props.config.apihost + '/configuration/files/' + props.settings.styling.files.layout_background_image.uuid})`
					: 'none',
			backgroundRepeat: 'repeat-y',
			backgroundSize: '100% auto',
			// minHeight: '100vh',
			position: 'relative',
		},
		'.generic-sidebar-main': {
			// color: 'white',
			color: (props) => props?.settings?.styling?.sidebar_background_contrast_color + ' !important',
			// backgroundColor: '#0060AF',
			backgroundColor: (props) => props?.settings?.styling?.sidebar_background_color,
			// backgroundImage: `url(${BackgroundImage})`,
			backgroundImage: (props) =>
				props?.settings?.styling?.files?.sidebar_background_image?.uuid
					? `url(${props.config.apihost + '/configuration/files/' + props.settings.styling.files.sidebar_background_image.uuid})`
					: 'none',
			backgroundRepeat: 'repeat-y',
			backgroundSize: '100% auto',
			color: 'black',
		},
		'.generic-sidebar-main *': {
			color: (props) => props?.settings?.styling?.sidebar_background_contrast_color + ' !important',
		},
		'.generic-side-bar': {
			// backgroundColor: '#E6F0F9',
			color: (props) => props?.settings?.styling?.sidebar_background_contrast_color,
			// backgroundColor: '#0060AF',
			backgroundColor: (props) => props?.settings?.styling?.sidebar_background_color,
			overflowX: 'hidden',
		},
		// '.generic-defaultlayout-main:before': {
		// 	backgroundColor: '#E6F0F9',
		//   backgroundImage: `url(${BackgroundImage})`,
		//   backgroundRepeat: 'repeat-y',
		//   backgroundSize: '100% auto',
		//   opacity: '90%',
		//   content: '""',
		//   position: 'absolute',
		//   top: '0px',
		//   right: '0px',
		//   bottom: '0px',
		//   left: '0px',
		// },
		'.generic-defaultlayout-content': {
			// marginTop: theme.spacing(6),
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(2),
			// [theme.breakpoints.down('md')]: {
			// 	marginTop: theme.spacing(4),
			// 	marginBottom: theme.spacing(2),
			// },
			// [theme.breakpoints.up('md')]: {
			// },
		},
		'.generic-footer': {
			padding: theme.spacing(2),
			marginTop: 'auto',
			backgroundColor: '#f4f6f8',
			position: 'absolute',
			width: '100%',
			bottom: 0,
		},
		'.generic-form-main': {
			marginTop: theme.spacing(2),
			flexDirection: 'column',
			alignItems: 'center',
		},
		'.generic-width-max': {
			width: '100%',
		},
		'.generic-form-main-no-margin': {
			flexDirection: 'column',
			alignItems: 'center',
		},
		'.generic-form-content-form': {
			width: '100%',
		},
		'.generic-form-field-required-error': {
			color: '#f44336',
		},
		'.generic-form-content-form-button': {
			margin: theme.spacing(3, 0, 2),
		},
		'.generic-form-content-form-back-button': {
			margin: theme.spacing(1, 0, 2),
		},
		'.generic-form-content-form-field-dropzone-container': {
			minHeight: 'unset',
		},
		'.generic-form-content-form-field-dropzone-text': {
			fontSize: theme.typography.fontSize,
			fontFamily: theme.typography.fontFamily,
		},
		'.generic-form-content-form-field-slider': {
			paddingLeft: theme.spacing(3),
			paddingTop: theme.spacing(3),
		},
		'.generic-form-content-form-field-slider-suffix-left-padding': {
			paddingLeft: theme.spacing(2) + ' !important',
		},
		'.generic-loading-in-component': {
			position: 'absolute',
			left: '50%',
			top: '50%',
			transform: 'translate(-50%, -50%)',
		},
		'.generic-loading-progress': {
			margin: theme.spacing(2),
		},
		'.generic-loading-progress-buttons': {
			marginRight: theme.spacing(1),
			display: 'block',
		},
		'.generic-messagedisplay-button-bar': {
			display: 'flex',
			justifyContent: 'center',
		},
		'.generic-messagedisplay-button-go-to-case': {
			display: 'flex',
			justifyContent: 'center',
			marginBottom: theme.spacing(1),
		},
		'.generic-messagetoast-icon': {
			fontSize: 20,
		},
		'.generic-messagetoast-icon-variant': {
			opacity: 0.9,
			marginRight: theme.spacing(1),
		},
		'.generic-messagetoast-message': {
			// display: 'flex',
			alignItems: 'center',
		},

		'.generic-navbar-main': {
			// backgroundColor: 'white !important',
			color: (props) => props?.settings?.styling?.navbar_background_contrast_color,
			// backgroundColor: '#E6F0F9',
			backgroundColor: (props) => props?.settings?.styling?.navbar_background_color,
			// backgroundImage: (props) =>
			// 	props?.settings?.styling?.files?.navbar_background_image?.uuid
			// 		? `url(${props.config.apihost + '/configuration/files/' + props.settings.styling.files.navbar_background_image.uuid})`
			// 		: 'none',
			// backgroundRepeat: 'repeat-y',
			// backgroundSize: '100% auto',
		},

		'.generic-navbar-main-logo': {
			marginRight: theme.spacing(2),
			padding: '0px',
			borderRadius: '0px',
			'&:hover': {
				backgroundColor: 'unset',
			},
		},
		'.generic-navbar-section-desktop': {
			marginLeft: theme.spacing(1),
			display: 'none',
			[theme.breakpoints.up('md')]: {
				display: 'flex',
				alignItems: 'center',
			},
		},
		'.generic-navbar-section-notifications': {
			marginLeft: theme.spacing(1),
		},
		'.generic-navbar-section-mobile': {
			marginLeft: theme.spacing(1),
			display: 'flex',
			[theme.breakpoints.up('md')]: {
				display: 'none',
			},
		},
		'.generic-navbar-menu-item': {
			paddingLeft: '0px',
		},
		'.generic-navbar-section-active': {
			paddingTop: '1px',
			paddingBottom: '1px',
			backgroundColor: convertHex(theme.palette.primary.main, 5),
			border: (props) =>
				typeof props.settings.styling !== 'undefined' && props.settings.styling.length > 0
					? props.settings.styling.menu_link_borders && props.settings.styling.menu_link_borders
						? '1px solid ' + theme.palette.primary.main
						: 'none'
					: '1px solid ' + theme.palette.primary.main,
			borderRadius: theme.shape.borderRadius,
			'&:hover, &.Mui-disabled': {
				backgroundColor: convertHex(theme.palette.primary.main, 5) + ' !important',
			},
		},
		'.generic-navbar-menu-active': {
			backgroundColor: convertHex(theme.palette.primary.main, 5),
			border: '1px solid ' + theme.palette.primary.main,
		},
		'.generic-panellist-main': {
			margin: theme.spacing(2, 0),
		},
		'.generic-panellist-title': {
			fontSize: theme.typography.pxToRem(15),
			flexBasis: '33.33%',
			flexShrink: 0,
		},
		'.generic-panellist-subtitle': {
			fontSize: theme.typography.pxToRem(15),
			color: theme.palette.text.secondary,
			paddingLeft: theme.spacing(1),
		},
		'.generic-panellist-warning': {
			flexGrow: '1',
			textAlign: 'end',
		},
		'.generic-table-main': {
			maxWidth: '100%',
		},
		'.generic-display-block': {
			display: 'block',
		},
		'.generic-tabmenu-main': {
			position: 'absolute',
			right: '0px',
			[theme.breakpoints.down('md')]: {
				position: 'unset',
			},
			// backgroundColor: theme.palette.primary.main,
		},
		'.generic-tabmenu-icon': {
			borderRadius: 'unset',
			// color: theme.palette.primary,
		},
		'.generic-treelist-treeitem-label': {
			display: 'flex',
			alignItems: 'center',
			padding: theme.spacing(0.5, 0),
		},
		'[class*=PreviewList-smallPreviewImg]': {
			width: 'unset',
			height: 'fit-content',
			maxHeight: '250px',
			borderStyle: 'groove',
			'&:hover': {
				backgroundImage: `url(${ZoomImage})`,
				backgroundRepeat: 'no-repeat',
				backgroundSize: '25%',
				// backgroundPosition: 'center',
				// backgroundSize: 'cover'
			},
		},
		'[class*=PreviewList-imageContainer]': {
			flexGrow: 0,
			maxWidth: '100%',
			flexBasis: '100%',
		},
		'.ReactGridGallery_tile-icon-bar div': {
			[theme.breakpoints.down('xl')]: {
				visibility: 'visible !important',
			},
		},
		'.ReactGridGallery_tile-icon-bar': {
			[theme.breakpoints.down('xl')]: {
				backgroundColor: 'rgb(224, 224, 224, 0.8) !important',
			},
		},
		'.generic-audio-root': {
			display: 'flex',
			justifyContent: 'center',
		},
		'.navbar-links-button': {
			fontSize: theme.typography.h5.fontSize,
			textTransform: 'none',
		},
		'.navbar-links-disabled-button': {
			fontSize: theme.typography.h5.fontSize,
			textTransform: 'none',
			cursor: 'unset !important',
			color: theme.palette.primary.main + ' !important',
			marginLeft: theme.spacing(1),
		},
		'.generic-link-button': {
			border: 'unset',
			padding: '6px 16px',
			backgroundColor: '#e0e0e0',
		},
		'.generic-form-content-form-field-select-group-child': {
			marginLeft: theme.spacing(2),
		},
		'.generic-form-autocomplete-error label': {
			color: '#f44336',
		},
		'.generic-form-autocomplete-error fieldset': {
			borderColor: '#f44336',
		},

		'.generic-hr-text': {
			lineHeight: '1em',
			position: 'relative',
			outline: '0',
			border: '0',
			color: 'black',
			textAlign: 'center',
			height: '1.5em',
			opacity: '.5',
			'&:before': {
				content: '""',
				background: 'linear-gradient(to right, transparent, #818078, transparent)',
				position: 'absolute',
				left: '0',
				top: '50%',
				width: '100%',
				height: '1px',
			},
			'&:after': {
				content: 'attr(data-content)',
				position: 'relative',
				display: 'inline-block',
				color: 'black',

				padding: '0 .5em',
				lineHeight: '1.5em',
				color: '#818078',
				backgroundColor: '#fcfcfa',
			},
		},
		'.MuiDialogActions-root': {
			justifyContent: 'space-between',
		},
		'.generic-image-display-root': {
			maxWidth: 'calc(var(--maxWidth) * 1px)',
		},

		'.generic-image-display-wrapper': {
			position: 'relative',
			height: '0',
			paddingTop: 'calc(var(--height) / var(--width) * 100%)',
			background: '#f6f7f8',
			background: 'linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%)',
			backgroundSize: '1000px 640px',
			animation: 'placeHolderShimmer 1.8s linear infinite forwards',
		},
		'.generic-image-display-img': {
			position: 'absolute',
			top: '0',
			left: '0',
			maxWidth: '100%',
			height: 'auto',
			width: 'auto',
			verticalAlign: 'middle',
		},

		'@keyframes placeHolderShimmer': {
			'0%': {
				backgroundPosition: '-468px 0',
			},
			'100%': {
				backgroundPosition: '468px 0',
			},
		},
		'.generic-form-content-form-field-multiautocomplete-suffix-left-padding': {
			paddingLeft: theme.spacing(3) + ' !important',
			paddingTop: theme.spacing(1) + ' !important',
		},
		'.generic-form-content-form-field-multiautocomplete-suffix-top-padding': {
			paddingTop: theme.spacing(1) + ' !important',
		},
		'.number-input::-webkit-inner-spin-button': {
			WebkitAppearance: 'none',
			margin: '0',
		},
		'.number-input::-webkit-outer-spin-button': {
			WebkitAppearance: 'none',
			margin: '0',
		},
		'.number-input': {
			MozAppearance: 'textfield',
		},
		'.generic-divider-main-color': {
			backgroundColor: theme.palette.primary.main,
		},
		'.generic-divider-activity-color': {
			backgroundColor: theme.palette.grey[500],
		},
		'.generic-paper-padding': {
			padding: theme.spacing(2),
		},
		'.generic-container-display-flex': {
			display: 'flex',
		},
		'.generic-middle-button-group': {
			margin: theme.spacing(0, 0, 2),
		},
		'.generic-text-uppercase': {
			textTransform: 'uppercase',
		},
		'.default-layout-background-color-grey-700': {
			backgroundColor: theme.palette.grey[700],
		},
		'.default-layout-background-color-grey-800': {
			backgroundColor: theme.palette.grey[800],
		},
		'.default-layout-background-color-grey-900': {
			// backgroundColor: theme.palette.grey[900],
		},
		'.generic-avatar': {
			width: theme.spacing(5) + ' !important',
			height: theme.spacing(5) + ' !important',
		},
		'.generic-avatar-primary': {
			backgroundColor: theme.palette.primary.main + '!important',
		},
		'.generic-avatar-secondary': {
			backgroundColor: theme.palette.secondary.main + '!important',
		},
		'.generic-groupbutton-back': {
			margin: theme.spacing(6, 0),
		},
		'.generic-modal-progress': {
			alignItems: 'center',
			justifyContent: 'flex-end !important',
		},
		'.generic-text-align-justify': {
			textAlign: 'justify',
		},
		'.generic-text-align-end': {
			textAlign: 'end',
		},
		'.table-big-text-ellipsis': {
			textAlign: 'justify',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			display: '-webkit-box',
			WebkitLineClamp: '3',
			WebkitBoxOrient: 'vertical',
			'&:hover': {
				display: 'block',
			},
		},
		'.generic-text-align-center': {
			textAlign: 'center',
		},
		'.generic-align-content-start': {
			alignContent: 'start',
		},
		'.generic-margin-bottom-2': {
			marginBottom: theme.spacing(2),
		},
		'.generic-avatar-size': {
			width: theme.spacing(6),
			height: theme.spacing(6),
		},
		'.generic-pointer': {
			cursor: 'pointer',
		},
		'.generic-width-40': {
			width: '40%',
		},
		'.generic-width-backoffice': {
			width: '16%',
		},
		'.generic-palette-primary-color': {
			color: theme.palette.primary.main,
		},
		'.custom-dialog .MuiBackdrop-root': {
			backgroundColor: 'transparent',
		},
		'.generic-journal_button-color': {
			borderRadius: '15px',
			color: 'white',
			fontSize: '12px',
			backgroundColor: '#0060AF',
			border: '1px solid #0060AF',
			width: 'auto',
		},
		'.generic-link-color': {
			color: theme.palette.primary.contrastText,
		},
	};
};

export default generic;
